// src/pages/CreateEvent.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function CreateEvent() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [date, setDate] = useState('');
  const [coordinates, setCoordinates] = useState([]);
  const [tags, setTags] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        'http://78.141.219.240:5000/api/events',
        {
          name,
          description,
          category,
          date,
          coordinates: coordinates.map((coord) => parseFloat(coord)),
          tags: tags.split(',').map((tag) => tag.trim()),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Event created successfully');
      navigate('/');
    } catch (err) {
      alert(err.response.data.error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Create Event</h2>
      <input
        type="text"
        placeholder="Event Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <textarea
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <input
        type="text"
        placeholder="Category"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      />
      <input
        type="datetime-local"
        value={date}
        onChange={(e) => setDate(e.target.value)}
      />
      <input
        type="text"
        placeholder="Coordinates (lng,lat)"
        value={coordinates}
        onChange={(e) => setCoordinates(e.target.value.split(','))}
      />
      <input
        type="text"
        placeholder="Tags (comma-separated)"
        value={tags}
        onChange={(e) => setTags(e.target.value)}
      />
      <button type="submit">Create Event</button>
    </form>
  );
}

export default CreateEvent;
