// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import axios from 'axios';

function Home() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    axios.get('http://78.141.219.240:5000/api/events').then((res) => {
      setEvents(res.data);
    });
  }, []);

  const mapStyles = {
    height: '100vh',
    width: '100%',
  };

  const defaultCenter = {
    lat: 37.7749,
    lng: -122.4194,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBQGUVyaa-nrxEkiDY6qib3Zmp3HH-ouh8">
      <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={defaultCenter}>
        {events.map((event) => (
          <Marker
            key={event._id}
            position={{
              lat: event.location.coordinates[1],
              lng: event.location.coordinates[0],
            }}
            onClick={() => setSelectedEvent(event)}
          />
        ))}
        {selectedEvent && (
          <InfoWindow
            position={{
              lat: selectedEvent.location.coordinates[1],
              lng: selectedEvent.location.coordinates[0],
            }}
            onCloseClick={() => setSelectedEvent(null)}
          >
            <div>
              <h3>{selectedEvent.name}</h3>
              <p>{selectedEvent.description}</p>
              <p>{new Date(selectedEvent.date).toLocaleString()}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default Home;
