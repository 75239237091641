// src/pages/Register.js
import React, { useState } from 'react';
import axios from 'axios';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [interests, setInterests] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://78.141.219.240:5000/api/users/register', {
        username,
        password,
        interests: interests.split(',').map((interest) => interest.trim()),
      });
      alert('Registration successful');
    } catch (err) {
      alert(err.response?.data?.error || 'Registration failed');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Register</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="text"
        placeholder="Interests (comma-separated)"
        value={interests}
        onChange={(e) => setInterests(e.target.value)}
      />
      <button type="submit">Register</button>
    </form>
  );
}

export default Register;
